import React from 'react';
import { Brick, BrickSocialPlatform, BrickSubType } from 'components/bricks/types/brick.type';
import { Placement, PlacementsSection } from 'components/bricks/types/placement.type';
import { CreativeV2Enriched } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import cloneDeep from 'helpers/cloneDeep';
import DynamicAssetV2 from 'components/creatives-v2/components/dynamic-asset-v2';
import { ISocialPreview, PreviewData, AdSetupFrameType } from 'components/creatives-v2/components/social-previews-v2/social-preview.type';
import { ITikTokData, TikTokPlacementKey } from 'components/creatives-v2/components/social-previews-v2/components/tiktok/types/tiktok-preview.type';
import { IMetaData, MetaPlacementKey } from 'components/creatives-v2/components/social-previews-v2/components/meta/types/meta-preview.type';
import { ISnapchatData, SnapchatPlacementKey } from 'components/creatives-v2/components/social-previews-v2/components/snapchat/types/snapchat-preview.type';
import ChannelItem from 'components/creative-builder/CreativeBuilderItem/components/channel-item';
import tiktokPlacements from 'components/bricks/components/bricks/social/tiktok/data/placements';
import youtubePlacements from 'components/bricks/components/bricks/video/youtube/data/placements';
import snapchatPlacements from 'components/bricks/components/bricks/social/snapchat/data/placements';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { IYoutubeData, YoutubePlacementKey } from 'components/creatives-v2/components/social-previews-v2/components/youtube/types/youtube-preview.type';
import { AvailablePreviewPlacement } from '../types/CreativePreview.type';

class CreativePreviewHelper {
    /**
     * Retrieves the ad data for a given brick, including inputs like headline, description, etc.
     *
     * @param {Brick} brick - The brick object containing ad setup data.
     * @returns {any} The ad setup data with the first item's data merged if available.
     */
    static getAdData(brick: Brick) {
        const adSetup = cloneDeep(brick?.data?.adSetup);
        return adSetup?.items?.length ? { ...adSetup, ...adSetup.items?.[0] } : adSetup || {};
    }

    /**
     * Generates iframe overwrites based on the creative type and active placement.
     *
     * @param {CreativeV2Enriched} creative - The enriched creative object.
     * @param {Placement | null} activePlacement - The current active placement.
     * @returns {object} An object containing iframe overwrite settings.
     */
    static getIframeOverwrites(creative: CreativeV2Enriched, activePlacement: Placement | null, frameType: string) {
        const isPlayable = ['displayAdDesigned', 'dynamicVideoDesigned'].includes(creative.data.templateType);
        const width = (() => {
            if (activePlacement?.key === 'facebook-feed' && frameType === 'multi') return 230;
            return activePlacement?.frameWidth ?? 340;
        })();
        return {
            width: width,
            data: {
                editmode: false,
                showPlaybar: isPlayable,
                autoplay: isPlayable
            }
        };
    }

    /**
     * Creates social preview data based on the platform, preview data, active placement, and brick.
     *
     * @param {BrickSocialPlatform} platform - The platform for which the preview is being created (e.g., 'meta', 'tiktok', 'snapchat').
     * @param {PreviewData} previewData - The preview data including message, cta, items, etc.
     * @param {Placement | null} activePlacement - The current active placement.
     * @param {Brick} brick - The brick object containing the ad setup data.
     * @returns {ISocialPreview} The social preview object tailored to the specified platform.
     * @throws Will throw an error if the platform is unsupported.
     */
    static createSocialPreviewData(platform: BrickSocialPlatform, previewData: PreviewData, activePlacement: Placement | null, brick: Brick): ISocialPreview {
        const placementKey = `${activePlacement?.key}`;
        const frameType = brick?.data?.adSetup?.type; // single or multi type

        switch (platform) {
            case 'meta':
                return {
                    platform,
                    placementKey: placementKey as MetaPlacementKey,
                    frameType: frameType as AdSetupFrameType,
                    data: previewData as IMetaData
                };
            case 'tiktok':
                return {
                    platform,
                    placementKey: placementKey as TikTokPlacementKey,
                    frameType: frameType as AdSetupFrameType,
                    data: previewData as ITikTokData
                };
            case 'snapchat':
                return {
                    platform,
                    placementKey: placementKey as SnapchatPlacementKey,
                    frameType: frameType as AdSetupFrameType,
                    data: previewData as ISnapchatData
                };
            case 'youtube':
                return {
                    platform,
                    placementKey: placementKey as YoutubePlacementKey,
                    frameType: frameType as AdSetupFrameType,
                    data: previewData as IYoutubeData
                };
            default:
                throw new Error(`Unsupported platform: ${platform}`);
        }
    }

    /**
     * Generates the assets for a given brick and active placement.
     *
     * @param {CreativeV2Enriched[] | undefined} enrichedCreatives - Array of enriched creative objects.
     * @param {Brick} brick - The brick object containing ad setup data.
     * @param {Placement | null} activePlacement - The current active placement.
     * @returns {JSX.Element[]} An array of JSX elements representing the assets.
     */
    static getAssets(enrichedCreatives: CreativeV2Enriched[] | undefined, brick: Brick, activePlacement: Placement | null) {
        const assets: JSX.Element[] = [];
        const adSetupItems = brick?.data?.adSetup?.items;

        if (!enrichedCreatives?.length || !adSetupItems?.length) return assets;

        adSetupItems.forEach((_item: any, index: number) => {
            const placement = this.getPlacement(brick, activePlacement, index);

            if (!placement) return;

            const formatKey = placement.formatKey;
            const creative = enrichedCreatives.find((creative) => creative.id === placement.creativeId);

            if (!creative) return;

            const iframeOverwrites = this.getIframeOverwrites(creative, activePlacement, brick?.data?.adSetup?.type);

            assets.push(<DynamicAssetV2 formatKey={formatKey} creative={creative} iframeOverwrites={iframeOverwrites} />);
        });

        return assets;
    }

    /**
     * Retrieves the placement data for a specific index within the brick's ad setup.
     *
     * @param {Brick} brick - The brick object containing ad setup data.
     * @param {Placement | null} activePlacement - The current active placement.
     * @param {number} index - The index of the ad setup item.
     * @returns {{ formatKey: string; creativeId: string } | undefined} An object containing format key and creative ID, or undefined if not found.
     */
    static getPlacement(brick: Brick, activePlacement: Placement | null, index: number): { formatKey: string; creativeId: string } | undefined {
        const currentAdSetupItem = brick?.data?.adSetup?.items?.[index];

        if (currentAdSetupItem) {
            const availablePlacements = currentAdSetupItem.placements;

            if (availablePlacements && activePlacement?.key) {
                return availablePlacements[activePlacement.key];
            }

            // If placements doesn't exist, return the first asset from the first item
            return {
                formatKey: activePlacement?.key ?? 'default-format',
                creativeId: currentAdSetupItem.creativeIds?.[0] || ''
            };
        }

        return undefined;
    }

    /**
     * Generates social preview data for the given brick, creatives, and placement.
     *
     * @param {Brick} brick - The brick object containing ad setup data.
     * @param {CreativeV2Enriched[] | undefined} enrichedCreatives - Array of enriched creative objects.
     * @param {Placement | null} activePlacement - The current active placement.
     * @returns {ISocialPreview | null} The social preview data or null if platform/placement is not available.
     */
    static generateSocialPreviewData(
        brick: Brick,
        enrichedCreatives: CreativeV2Enriched[] | undefined,
        activePlacement: Placement | null
    ): ISocialPreview | null {
        const platformType = activePlacement?.platform;
        const adData = this.getAdData(brick);

        const translatedAdData = cloneDeep(adData);
        ChannelItem.translateData('EN', translatedAdData);

        const previewData: PreviewData = {
            message: translatedAdData.message || '',
            cta: translatedAdData.cta || '',
            items: translatedAdData.items
        };

        const assets = this.getAssets(enrichedCreatives, brick, activePlacement);

        if (assets.length) {
            const assetMap = new Map<string, JSX.Element>();
            assets.forEach((asset: JSX.Element) => {
                const creativeId = asset.props.creative.id;
                if (!assetMap.has(creativeId)) {
                    assetMap.set(creativeId, asset);
                }
            });

            previewData.items?.forEach((item: any) => {
                item.creativeIds?.some((creativeId: string) => {
                    if (assetMap.has(creativeId)) {
                        item.asset = assetMap.get(creativeId);
                        return true;
                    }
                    return false;
                });
            });
        }

        if (!activePlacement || !platformType) return null;

        return this.createSocialPreviewData(platformType, previewData, activePlacement, brick);
    }

    /**
     * Get the available preview placements for a given brick and ad setup type.
     *
     * @param {Brick} brick - The brick object containing ad setup data.
     * @param {AdSetupFrameType} adSetupType - The type of ad setup (single or multi).
     * @returns {AvailablePreviewPlacement[]} The available preview placements.
     */
    static getAvailablePreviewPlacements(brick: Brick, adSetupType: AdSetupFrameType): AvailablePreviewPlacement[] {
        if (!brick) return [];

        if (brick.subType === 'tiktok_ad') {
            return tiktokPlacements[0].children.map((placement) => ({
                value: placement.key,
                label: placement.label
            }));
        }

        if (brick.subType === 'snapchat_ad') {
            const snapchatPlacementsFiltered: AvailablePreviewPlacement[] = [];

            snapchatPlacements.forEach((section) => {
                section.children.forEach((placement) => {
                    if (
                        (adSetupType === 'single' && placement.key === 'snapchat-ad') ||
                        (adSetupType === 'multi' && (placement.key === 'snapchat-story' || placement.key === 'snapchat-collection'))
                    ) {
                        snapchatPlacementsFiltered.push({
                            value: placement.key,
                            label: placement.label
                        });
                    }
                });
            });

            return snapchatPlacementsFiltered;
        }

        if (brick.subType === 'youtube_post') {
            return youtubePlacements[0].children.map((placement) => ({
                value: placement.key,
                label: placement.label
            }));
        }

        const parentBrick = BrickHelpers.getBrickParent(brick, 'meta_adset');
        const placementKeys = parentBrick?.data?.settings?.targeting?.placements || this.getAllPlacementKeys(brick.subType);

        if (!placementKeys || !Array.isArray(placementKeys)) return [];

        // Filter placements based on the multiFrame property
        const filteredPlacements = placementKeys.filter((placementKey) => {
            const placement = this.getPlacementObject(brick.subType, placementKey);
            return placement && (adSetupType === 'single' || placement.multiFrame);
        });

        // Map over the placements and include corresponding labels
        return filteredPlacements.map((placementKey) => {
            const placement = this.getPlacementObject(brick.subType, placementKey);
            return {
                value: placementKey,
                label: placement?.label ?? placementKey
            };
        });
    }

    /**
     * Retrieves the placement object based on the brick subtype and placement key.
     *
     * @param {BrickSubType} subType - The subtype of the brick.
     * @param {string} placementKey - The key of the placement.
     * @returns {Placement | null} The placement object or null if not found.
     */
    static getPlacementObject(subType: BrickSubType, placementKey: string): Placement | null {
        const placements: PlacementsSection[] = BrickHelpers.getBrickData(subType, 'placements');
        if (placements) {
            for (const section of placements) {
                const activePlacement = section.children.find((placement: Placement) => placement.key === placementKey);
                if (activePlacement) {
                    return activePlacement;
                }
            }
        }
        return null;
    }

    /**
     * Retrieves all placement keys based on the brick subtype
     *
     * @param {BrickSubType} subType - The subtype of the brick.
     * @returns {Placement | null} The placement keys or undefined if not found.
     */
    static getAllPlacementKeys(subType: BrickSubType): string[] {
        const sections: PlacementsSection[] | undefined = BrickHelpers.getBrickData(subType, 'placements');
        const placementKeys: string[] = [];
        if (sections) {
            for (const section of sections) {
                for (const placement of section.children) {
                    placementKeys.push(placement.key);
                }
            }
        }
        return placementKeys;
    }
}

export default CreativePreviewHelper;
